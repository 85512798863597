import { mainAxios } from 'libs/axios'

interface ListDepositProps {
  type?: string
  limit?: string | number
  size?: string | number
  page?: string | number
}

export const getListDeposit = (payload: ListDepositProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/me/deposit/history`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface DepositProps {
  money: number
  is_agree?: boolean
}

export const getDeposit = (payload: DepositProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/me/deposit`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface DeleteDepositProps {
  ids: any
}

export const deleteDeposit = (payload: DeleteDepositProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/me/deposit/delete`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
