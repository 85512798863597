import { mainAxios } from 'libs/axios'

interface MessageProps {
  size?: string | number
  page?: string | number
}

export const getNoticeHeader = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/site/message`,
    requiresToken: false,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

export const getMessage = (payload: MessageProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/message`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface ReadMessageDetailProps {
  id?: string | number
}

export const getReadMessageDetail = (payload: ReadMessageDetailProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/message/${payload.id}`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

export const getReadMessageAll = (mb_no: any) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/message/read-all/${mb_no}`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface DeleteMessageProps {
  ids?: any
}

export const getDeleteMessage = (payload: DeleteMessageProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/message/delete`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
