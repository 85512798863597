import styled from 'styled-components'
export const Footers = styled.div`
  margin: 70px 0;
  width: 100%;
  font-size: 12px;
  color: #8b8c8f;
  line-height: 19px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  span {
    margin: 0 3px;
    color: #fbbd01;
  }
  .left-box{
    display: flex;
  }
  .left {
    .left-button {
      background: transparent;
      .img-box {
        justify-content: center;
        width: 100%;
        display: flex;
        align-items: flex-end;
        .div-img{
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          
          .img-hover {
            display: none;
            width: 100%;
          }
          .img-normal {
            display: block;
            width: 100%;
          }
          :hover {
            .img-normal {
              display: none;
              width: 100%;

            }
            .img-hover {
              display: block;
              width: 100%;
            }
            
          }
        }
        img {
          // max-width: 45px;
          @media screen and (max-width: 1024px) {
            width: 30px;
          }
        }
      }
      
    }
    .left-title {
      text-align: left;
      background: #00EBE7;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 0px 6px;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 200px;
    img {
      width: 100%;
    }
  }
  .right {
    .right-title {
      text-align: left;
      background: var(
        --Gold-stroke,
        linear-gradient(180deg, #feeac3 0%, #ffe6a6 29.17%, #ffb800 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .right-box {
      max-width: 300px;
      text-align: start;
      cursor: pointer;
      span {
        margin-top: 5px;
        color: white;
        margin-right: 10px;
        display: inline-block;
        :hover {
          background: var(
            --Gold-stroke,
            linear-gradient(180deg, #feeac3 0%, #ffe6a6 29.17%, #ffb800 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }
`
