import { mainAxios } from 'libs/axios'

interface ListPointProps {
  limit?: string | number
  page?: string | number
}

export const getListPoint = (payload: ListPointProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `me/point/history`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface ChangePointProps {
  money?: string | number
}

export const getChangePoint = (payload: ChangePointProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `me/point/change-point`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
